import "./buttonConnect.css";

interface Props {
  buttonText?: string;
  disabled?: boolean;
  color?: string;
  textColor?: string;
  width?: string;
  isHeader?: boolean;
}

const ButtonConnect = ({ buttonText, disabled, color, textColor, width, isHeader }: Props) => {
  function buyWithRedirect() {
    window.location.href =
      "https://x5paket.ru/fastbuy_promo?promocode=LITRES24&litres=Y";
  }
  const isMobile = function () {
    return window.innerWidth <= 767;
  };
  const w = isMobile() ? {} : { width };
  return (
    <button
      className="custom-button"
      disabled={disabled}
      style={{
        backgroundColor: color,
        color: textColor,
        ...w
      }}
      onClick={() => {
        window.ym(79010596,'reachGoal','x5paket-podarok-buy', {shocolad: `podkluchit${isHeader ? '1' : '2'}`})
        buyWithRedirect();
      }}
    >
      {buttonText}
    </button>
  );
};

export default ButtonConnect;
