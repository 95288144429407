import LandingContent from "@components/landingContent/landingContent";
import "./landing.css";
import LandingFooter from "@components/landingFooter/landingFooter";
import LandingHeader from "@components/landingHeader/landingHeader";
import { Outlet } from "react-router-dom";

const Landing = () => {
  let token = localStorage.getItem('token')
  // if(token){
  //   window.location.href='/terms'
  // }

  return (

    <div className="scroll-listener">
      <LandingHeader></LandingHeader>
      <div><LandingContent></LandingContent></div>
      <div className="landing-footer">
        <LandingFooter></LandingFooter>
      </div>
    </div>
  );
};

export default Landing;
