import "./profile.css";
import profile from "@assets/icons/profile.svg";
import exit from "@assets/icons/exit.svg";
import { useState } from "react";
import Modal from "@components/modal/modal";

interface Props {
  name?: string;
}

const Profile = ({ name }: Props) => {
  const [showPopup, setPopup] = useState(false);
  const [showModal, setModal] = useState(false);

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleExitModal = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("code");
    window.location.href = "/";
  };

  return (
    <div className="profile" onClick={() => setPopup(!showPopup)}>
      <img src={profile} className="profile-icon" alt=""></img>
      {showPopup ? (
        <div className="profile-popup">
          <div className="profile-name">{name}</div>
          <div
            className="profile-exit"
            onClick={(e) => {
              window.ym(79010596,'reachGoal','x5paket-podarok-clk', {shocolad: 'logout'})
              setModal(true);
              setPopup(false);
              e.stopPropagation();
            }}
          >
            <img src={exit} alt=""></img>
            <div>Выйти</div>
          </div>
        </div>
      ) : null}
      {showModal ? (
        <Modal
          isOpen={showPopup}
          onClose={handleCloseModal}
          onExit={handleExitModal}
        ></Modal>
      ) : null}
    </div>
  );
};

export default Profile;
