import './footerlogo.css';

interface Props{
  logo?: string
}

const FooterLogo = ({logo}: Props) => {
  return (
    <div>
      <img src={logo}></img>
    </div>
  );
}

export default FooterLogo;