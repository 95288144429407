import "./giftButton.css";

interface Props {
  data?: string;
}

const GiftButton = ({ data }: Props) => {
  function getRequestUrl() {
    return process.env.REACT_APP_ENV === "dev"
        ? process.env.REACT_APP_AUTH0_DEV_CALLBACK_URL
        : process.env.REACT_APP_AUTH0_PROD_CALLBACK_URL;
  };

  function logIn() {
    const authDomen = process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_API_DEV_SERVER_URL
    : process.env.REACT_APP_API_PROD_SERVER_URL;
    const params = new URLSearchParams({ request_url: getRequestUrl() } as unknown as string).toString()
    const url = `${authDomen}auth?${params}`;
    window.location.href = url;
  }

  return (
    <div className="gift-button-container">
      <button
        className="gift-button"
        onClick={() => {
          window.ym(79010596,'reachGoal','x5paket-podarok-clk', {shocolad: 'take-part'})
          logIn();
        }}
      >
        {data}
      </button>
    </div>
  );
};

export default GiftButton;
