import "./copyright.css";

interface Props {
  info?: Array<string>;
}

const Copyright = ({ info }: Props) => {
  return (
    <div className="copyright-container">
      {info?.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
};

export default Copyright;
