import { ReactNode } from "react";
import "./giftTerms.css";

interface Props {
  data?: ReactNode;
}

const GiftTerms = ({ data }: Props) => {
  return <div className="gift-terms-container">{data}</div>;
};

export default GiftTerms;
