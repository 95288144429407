import xicon from "@assets/icons/collab-x-icon.svg";
import "./collabLogo.css";

interface Props {
  logo?: string;
}

const CollabLogo = ({ logo }: Props) => {
  return (
    <div className="collab-logo">
      <div className="plus-icon">
        <img src={xicon} alt="" />
      </div>
      <div className="company-logo">
        <img src={logo} alt=""></img>
      </div>
    </div>
  );
};

export default CollabLogo;
