import "./socialweb.css";
import { Link } from "react-router-dom";
interface Props {
  socialWebLogos?: {
    src: string;
    url: string;
  }[];
}

const SocialWebButton = ({ socialWebLogos }: Props) => {
  return (
    <div className="social-web-button">
      {socialWebLogos?.map((item, index) => (
        <Link to={item.url} target="_blank" rel="noopener noreferrer">
          <img key={index} src={item.src} alt="" className={index === 0 ? "social-web-icon-first" : "social-web-icon" }></img>
        </Link>
      ))}
    </div>
  );
};

export default SocialWebButton;
