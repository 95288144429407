import "./giftPeriod.css";

interface Props {
  data?: string;
}

const GiftPeriod = ({ data }: Props) => {
  return <div className="gift-period-container">{data}</div>;
};

export default GiftPeriod;
