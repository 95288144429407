import jwt from "jwt-decode";

const API_URL = process.env.REACT_APP_ENV === "dev" ? process.env.REACT_APP_API_DEV_SERVER_URL : process.env.REACT_APP_API_PROD_SERVER_URL;
const REFRESH_TOKEN_URL = `${API_URL}api/refresh`;

const START_DATE = new Date(process.env.REACT_APP_START_DATE as string);
const END_DATE = new Date(process.env.REACT_APP_END_DATE as string);
const END_DATE_EXTENDED = new Date(process.env.REACT_APP_END_DATE_EXTENDED as string);


let interval: any = undefined

const isVerified = (token: any) => token?.verified;
const isSubscription = (token: any) => (token?.subscription).toString() === "true";

const addDays = (date: Date, days: number) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const isVerifiedAndSubscription = (token: any) => {
  return isVerified(token) && isSubscription(token);
}

const isVerifiedAndNoSubscription = (token: any) => {
  return isVerified(token) && !isSubscription(token);
}

const isSubscriptionBlocked = (token: any) => {
  return isVerifiedAndSubscription(token) || !token?.verified;
}

const formatDate = (date: Date) => `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
const getFormattedStartDate = () => formatDate(START_DATE);
const getFormattedEndDate = () => formatDate(END_DATE);
const getFormattedEndDateExtended = () => formatDate(END_DATE_EXTENDED);

const isPromoEarly = () => new Date() < START_DATE;
const isPromoOver = () => new Date() > END_DATE;
const isPromoOverExtended = () => new Date() > END_DATE_EXTENDED;
const isPromoNow = () => !(isPromoEarly() || isPromoOver());


const startUpdateTokensLoop = (callback: Function, ms: number) => {
  stopUpdateTokensLoop();

  const initToken: any = localStorage.getItem("access") ? jwt(localStorage.getItem("access") as string) : null;
  callback(initToken, true)

  interval = setInterval(() => {
    const refresh = localStorage.getItem("refresh");
    fetch(REFRESH_TOKEN_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${refresh}`,
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then(text => {
          throw JSON.parse(text);
        });
      })
      .then((data) => {
        const accessToken = data.access_token;
        const refreshToken = data.refresh_token;
        const decodedAccess = jwt(accessToken as string)

        localStorage.setItem("access", accessToken)
        localStorage.setItem("refresh", refreshToken)

        if (isVerifiedAndSubscription(decodedAccess)) {
          let apiUrl: string;
          if (process.env.REACT_APP_ENV === "dev") {
            apiUrl = process.env.REACT_APP_API_DEV_SERVER_URL as string;
          } else {
            apiUrl = process.env.REACT_APP_API_PROD_SERVER_URL as string;
          }
      
          stopUpdateTokensLoop();
          fetch(`${apiUrl}api/promo-codes`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then(response => {
              if (response.ok) {
                return response.json();
              }
              return response.text().then(text => {
                throw JSON.parse(text);
              });
            })
            .then(data => {
              localStorage.setItem("is_medovik", data.is_medovik);
              localStorage.setItem("code", data.code);
              callback(decodedAccess)
            })
            .catch(error => { 
              console.log(error)
            });
        } else {
          callback(decodedAccess);
        }
      })
      .catch(err => { })
  }, ms)
}


const stopUpdateTokensLoop = () => {
  if (interval) {
    clearInterval(interval);
  }
}

export {
  startUpdateTokensLoop,
  stopUpdateTokensLoop,
  isVerified,
  isSubscription,
  isVerifiedAndSubscription,
  isVerifiedAndNoSubscription,
  isSubscriptionBlocked,
  getFormattedStartDate,
  getFormattedEndDate,
  getFormattedEndDateExtended,
  isPromoEarly,
  isPromoOver,
  isPromoNow, isPromoOverExtended,
};