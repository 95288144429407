
import GuideButton from "./guideButton/guideButton";
import GuideContent from "./guideContent/guideContent";
import GuideHeader from "./guideHeader/guideHeader";
import "./landingGuide.css";
import box from "@assets/icons/box.svg";
import bonus from "@assets/icons/bonus.svg";
import percents from "@assets/icons/percents.svg";
import heart from "@assets/icons/heart.svg";
import coin from "@assets/icons/coin.svg";
import gift from "@assets/icons/gift.svg";

const LandingGuide = () => {
  let headerText = <>Ваша выгода <div>с сервисом «Пакет»</div></>;
  let headerTextMobile = <>Ваша выгода <div>с сервисом «Пакет»</div></>;
  let buttonText = "Узнать больше";
  let firstParagraphText =
    "Умножайте выгоду от ежедневных покупок:";
  let secondParagraphText = (
    <div>
      Начните экономить до <span style={{ fontWeight: 800 }}>5000 ₽ каждый месяц</span> на покупках {" "}
      в «Пятёрочке» и «Перекрёстке».
    </div>
  );

  const isMobile = function () {
    return window.innerWidth <= 767;
  };
  return (
    <div className="landing-guide-content">
      <div className="landing-guide-text-container">
        <GuideHeader data={isMobile() ? headerTextMobile : headerText}></GuideHeader>
        <GuideContent data={firstParagraphText}></GuideContent>
        <div className="bonus-list">
          <div className="bonus-list-row">
            <img src={percents} alt=""></img>
            <div className="bonus-list-text">
              получайте до 50% кешбэка в «Пятёрочке» и «Перекрёстке»
            </div>
          </div>
          <div className="bonus-list-row">
            <img src={heart} alt=""></img>
            <div className="bonus-list-text">
              выбирайте до 10 любимых категорий
            </div>
          </div>
          <div className="bonus-list-row">
            <img src={coin} alt=""></img>
            <div className="bonus-list-text">
              списывайте на покупки до 100% кешбэка 
            </div>
          </div>
          <div className="bonus-list-row">
            <img src={gift} alt=""></img>
            <div className="bonus-list-text">
              и получайте выгоды от партнёров 
            </div>
          </div>
        </div>
        <GuideContent data={secondParagraphText}></GuideContent>
        <GuideButton data={buttonText}></GuideButton>
      </div>
    </div>
  );
};

export default LandingGuide;
