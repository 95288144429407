import { ReactNode } from "react";
import "./guideContent.css";

interface Props {
  data: string | ReactNode;
}

const GuideContent = ({ data }: Props) => {
  return (
    <div className="guide-content-container">
      <div className="guide-content-row">{data}</div>
    </div>
  );
};

export default GuideContent;
