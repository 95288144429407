import './logo.css';
import logoDefault from '@assets/icons/header-logo-main.svg';


function Logo({logo = logoDefault}) {
  return (
    <img src={logo} alt='' className='logo-icon'></img>
  );
}

export default Logo;
