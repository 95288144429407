import ButtonConnect from "@components/header/buttonConnect/buttonConnect";
import "./terms.css";
import termsImage from "@assets/icons/terms-img.png";
import { getFormattedEndDate, getFormattedStartDate, getFormattedEndDateExtended, isPromoNow, isPromoOver } from "helpers/promoHelper";

const GIFT_TERMS_FILE_URL = process.env.REACT_APP_PROMO_RULES_URL;

interface Props {
  logo?: string;
}

const Terms = ({ logo }: Props) => {
  let isOpened = false;
  function handleClick() {
    isOpened ? window.usedeskMessenger.close() : window.usedeskMessenger.open();
    isOpened = !isOpened;
    return null;
  }

  let token = JSON.parse(localStorage.getItem("token") as string);
  if (!token) {
    window.location.href = "/";
  }

  if (isPromoOver()) {
    window.location.href = "/promocodes/expired"
  }


  let instructionsHeader = "Как получить подписку Литрес в подарок:";
  let instructionsList = [
    <>Нажмите на кнопку <b>«Подключить»</b></>,
    <>Оплатите сервис «Пакет» с промокодом <b>LITRES24</b> (убедитесь, что промокод уже введен)</>,
    <>После успешной оплаты нажмите на кнопку <b>«Получить»</b>. Вы вернетесь на сайт акции</>,
    <div>
      Дождитесь, пока промокод на подписку Литрес появится в разделе <b>«Мои промокоды»</b>,
      это может занять несколько минут. Если промокод не появится в течение 15
      минут, обратитесь в  <a onClick={handleClick}><b>поддержку</b></a>.
    </div>,
    "Введите промокод на сайте или в приложении Литрес и получите 3 месяца подписки бесплатно!",
  ];

  let termsHeader = "Условия акции:";
  let termsList = [
    `Промокод на подписку Литрес на 3 месяца можно получить в период с ${getFormattedStartDate()} по ${getFormattedEndDate()} включительно.`,
    "Акция действует только для новых пользователей сервиса «Пакет» или тех, у кого «Пакет» не был активен последние 62 дня.",
    `Промокод на подписку Литрес можно активировать до ${getFormattedEndDateExtended()} включительно.`,
    "Организатор акции вправе изменять правила или отменить акцию, при этом уведомление участников акции об изменении правил или отмене акции производится путём публикации соответствующих изменений на Сайте.",
  ];

  return (
    <div className="terms-page-container">
      <div className="instructions-wrapper">
        <div className="instructions-container">
          <div className="instructions-icon-container">
            <img src={termsImage} alt=""></img>
          </div>
          <div className="instructions-text-container">
            <div className="instructions-header">
              {" "}
              {instructionsHeader}
              {instructionsList.map((item, index) => (
                <div className="list-item-wrapper">
                  <div className="list-item-bullet">{index + 1}</div>
                  <div key={index} className="instructions-list-item">
                    {item}
                  </div>
                </div>
              ))}
            </div>
            <div className="connect-terms-button-container">
              <ButtonConnect
                buttonText={"Подключить"}
                disabled={token?.subscription === "true" || !isPromoNow()}
                color={"#0088FE"}
                textColor={"#ffffff"}
              ></ButtonConnect>
            </div>
          </div>
        </div>
      </div>
      <div className="terms-text-container">
        <ol className="terms-list-header">
          {" "}
          <span className="terms-text-header">{termsHeader}</span>
          {termsList.map((item, index) => (
            <div className="terms-list-item-wrapper">
              <li key={index} className="terms-list-item">
                {item}
              </li>
            </div>
          ))}
        </ol>
      </div>
      <div className="extra-container">
        Подробнее в{" "}
        <a
          href={GIFT_TERMS_FILE_URL}
          target="_blank"
          rel="noreferrer"
          className="extra-link"
        >
          Условиях акции.
        </a>
      </div>
    </div>
  );
};

export default Terms;
