import LandingGift from "@components/landingContent/landingGift/landingGift";
import "./landingContent.css";
import LandingSteps from "./landingSteps/landingSteps";
import LandingGuide from "./landingGuide/landingGuide";

const LandingContent = () => {
  return (
    <div>
      <div className="landing-gift">
        <LandingGift></LandingGift>
      </div>
      <div className="landing-progressbar-steps">
        <LandingSteps></LandingSteps>
      </div>
      <div className="landing-guide">
        <LandingGuide></LandingGuide>
      </div>
    </div>
  );
};

export default LandingContent;
