import "./App.css";
import "@assets/styles/typography.css";
import PromoAwait from "@components/promoAwait/promoAwait";
import PromocodeList from "@components/promoCodes/promocodeList";
import PromocodeExists from "@components/promoExists/promocodeExists";
import Expired from "@components/promoExpired/expired";
import { isPromoEarly, isPromoOverExtended, isSubscription, isVerified, isVerifiedAndNoSubscription, isVerifiedAndSubscription, startUpdateTokensLoop, stopUpdateTokensLoop } from "helpers/promoHelper";
import Landing from "pages/landing/landing";
import PromoToEarly from "pages/promoTooEarly/promoToEarly";
import Promocodes from "pages/promocodes/promocodes";
import TermsOfAction from "pages/termsOfAction/termsOfAction";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import jwt from 'jwt-decode'

function App() {
  const location = useLocation();
  const [token, setToken] = useState(
    localStorage.getItem("access") ? jwt(localStorage.getItem("access") as string) : null
  );

  if(location.pathname !== '/early' && isPromoEarly()) {
    window.location.href = '/early';
  }

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://lib.usedesk.ru/secure.usedesk.ru/widget_160726_42571.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/early') {
      stopUpdateTokensLoop();
      return;
    }
    if (location.pathname === '/promocodes/list') {
      window.ym(79010596,'reachGoal','x5paket-podarok-clk', {shocolad: 'my-promo'})
    }

    if (['/promocodes', '/promocodes/'].includes(location.pathname)) return;

    if (location.pathname !== '/early' && isPromoEarly() && process.env.REACT_APP_ENV !== "dev") {
      window.location.href = '/early';
      return;
    }

    if (location.pathname !== '/promocodes/expired' && isPromoOverExtended()) {
      window.location.href = '/promocodes/expired';
      return;
    }

    startUpdateTokensLoop((decodedToken: any, initial: boolean = false)=> {
      if (initial) {
        setToken(decodedToken);
        return;
      }

      if (isTokenChanged(token, decodedToken) && (
          isVerifiedAndSubscription(decodedToken) || (!isVerified(decodedToken) && isSubscription(decodedToken))
      )) {
        window.location.href = localStorage.getItem('is_medovik') === 'true' ? '/promocodes/list' : '/promocodes/exists';  
        return;
      }
 
      if (isTokenChanged(token, decodedToken) && isVerifiedAndNoSubscription(decodedToken)) {
        window.location.href = '/terms';  
        return;
      }
      
      if (isTokenChanged(token, decodedToken) && !isSubscription(decodedToken)) {
        window.location.href = '/promocodes/await';
        return;
      }

      setToken(decodedToken)
    }, 1000 * 60)

  }, [location])

  const isTokenChanged = (prevToken: any, newToken: any): boolean => {
    return !prevToken || isSubscription(prevToken) !== isSubscription(newToken) || isVerified(prevToken) || isVerified(newToken);
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="promocodes" element={<Promocodes token={token}></Promocodes>}>
          <Route path="expired" element={<Expired />}></Route>
          <Route path="exists" element={<PromocodeExists />}></Route>
          {/* <Route path="await" element={<PromoAwait token={token} />}></Route> */}
          <Route path="list" element={<PromocodeList token={token}></PromocodeList>}></Route>
        </Route>
        <Route path="terms" element={<TermsOfAction token={token}></TermsOfAction>}></Route>
        <Route path="early" element={<PromoToEarly></PromoToEarly>}></Route>
        <Route path="*" element={<Landing/>} />
      </Routes>
    </div>
  );
}

export default App;
