import "./notifyModal.css";
import checkboxIcon from "@assets/icons/checkboxIcon.svg";
import closeIcon from "@assets/icons/closeIcon.svg";

interface Props {
  message?: string;
  text?: string;
}

const NotifyModal = ({ text, message }: Props) => {
  return (
    <div className="notify-modal-container">
      <img src={checkboxIcon} alt="" className="checkbox-icon"></img>
      <div className="notify-modal-text-container">
      <div className="notify-modal-first-row">
        <div className="message-text">{message}</div>
      </div>
      <div className="notify-modal-second-row">
        <div className="notify-text">{text}</div>
      </div>
      </div>
      <img src={closeIcon} alt="" className="close-icon"></img>
    </div>
  );
};

export default NotifyModal;
