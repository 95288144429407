import { useState } from "react";
import "./guideButton.css";
import LandingModal from "@components/landingModal/landingModal";

interface Props {
  data: string;
}

const GuideButton = ({ data }: Props) => {
  const [showModal, setModal] = useState(false);

  const handleCloseModal = () => {
    setModal(false);
  };
  return (
    <div className="guide-button-container">
      <button className="guide-button" onClick={() => {
        window.ym(79010596,'reachGoal','x5paket-podarok-clk', {shocolad: 'more'})
        setModal(true)
      }}>
        {data}
      </button>
      {showModal ? (
        <LandingModal isOpen={showModal} onClose={handleCloseModal}></LandingModal>
      ) : null}
    </div>
  );
};

export default GuideButton;
