
import "./coupon.css";
import coupon from '@assets/icons/coupon-bg.svg'

interface Props{
    couponText?: string;
    couponCode?: string;
}
const Coupon = ({couponText, couponCode}:Props) => {
  return (
    <div className="coupon-container">
      <img src={coupon} alt='' className="coupon-icon"></img>
      <div className="coupon-info-container">
      <div className="coupon-text">{couponText}</div>
      <div className="coupon-code">{couponCode?.toUpperCase()}</div>
      </div>
    </div>
  );
};

export default Coupon;
