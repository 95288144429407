import React, { useState, useEffect, useRef } from "react";
import "./landingModal.css";
import close from "@assets/icons/close.svg";
import fivePercents from "@assets/icons/fivePercents.svg";
import fiftyPercents from "@assets/icons/fiftyPercents.svg";
import three from "@assets/icons/three.svg";
import tenPercents from "@assets/icons/tenPercents.svg";
import star from "@assets/icons/star.svg";
import twentyPercents from "@assets/icons/twentyPercents.svg";

interface LandingModalProps {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const LandingModal: React.FC<LandingModalProps> = ({
  isOpen,
  onClose,
}: LandingModalProps) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const modalData = [
    {
      icon: star,
      text: "5% кешбэк за покупки в «Пятёрочке», «Перекрёстке», Vprok.ru и Много лосося",
    },
    {
      icon: star,
      text: "возможность выбрать 10 любимых категорий «Х5 Клуба» в «Пятёрочке» и «Перекрёстке»",
    },
    {
      icon: star,
      text: "до 100% списания кешбэка на покупки",
    },
    {
      icon: star,
      text: "20% кешбэк на свежую выпечку и готовую еду в «Пятёрочке» и «Перекрёстке»",
    },
    {
      icon: star,
      text: "50% кешбэк за чашку чая или кофе в «Пятёрочке» и «Перекрёстке»",
    },
    {
      icon: star,
      text: "5% кешбэк за покупки во Vprok.ru",
    },
    {
      icon: star,
      text: "5% кешбэк за заказы в приложении Много лосося",
    },
    {
      icon: star,
      text: "бонусы от партнеров (Инвитро, Литрес, OneTwoTrip, Kari и Читай-город)",
    },
  ];
  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isModalOpen) {
        modalElement.show();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  return (
    <dialog
      ref={modalRef}
      className="landing-modal-popup"
      onKeyDown={handleKeyDown}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="landing-popup-window-container">
        <div className="landing-popup-content">
        <div className="landing-popup-header-container">
          <div className="landing-exit-container">
            <img src={close} alt="" onClick={handleCloseModal}></img>
          </div>
          <div className="landing-popup-header">Как работает сервис «Пакет»</div>
        </div>
        <div className="landing-popup-text-container">
        <div className="landing-popup-text">
          Сервис «Пакет» — это способ умножения выгоды, который открывает дополнительные 
          преимущества в магазинах «Пятёрочка» и «Перекрёсток». Стоимость одного месяца 
          — 199 рублей, но в рамках акции стоимость первого месяца для новых пользователей 
          составит 1 рубль.
        </div>
        <div className="landing-popup-list-header">
          Что дает сервис «Пакет»:
        </div>
        <div className="landing-popup-list">
          {modalData.map((item, index) => {
            return (
              <div className="landing-popup-list-item">
                <div className="landing-popup-icon">
                  <img src={item.icon} alt="" />
                </div>
                <div className="landing-popup-list-item-text">{item.text}</div>
              </div>
            );
          })}
        </div>
        <div className="landing-popup-footer">
          Оформите сервис «Пакет» на номер телефона, к которому привязана карта 
          лояльности «Х5 Клуба». Сервис автоматически подключится к вашей карте 
          и будет копить повышенный кешбэк.
        </div>
        </div>
        </div>
      </div>
    </dialog>
  );
};

export default LandingModal;
