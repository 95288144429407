import "./footer.css";
import Copyright from "@components/footer/copyright/copyright";
import FooterLogo from "@components/footer/footerlogo/footerlogo";
import SocialWebButton from "@components/footer/socialweb/socialweb";
import Policy from "@components/footer/policy/policy";
import x5Logo from "@assets/icons/x5logo.svg";
import vkLogo from "@assets/icons/vklogo.svg";

import { useState } from "react";

const Footer = () => {

  //Setting properties for components
  const [info, setInfo] = useState([
    "© 2024",
    "ООО «Корпоративный центр ИКС 5»",
  ]);

  const [policy, setPolicy] = useState(
    {
      description: "Сайт использует cookie-файлы. Продолжая пользоваться сайтом, вы соглашаетесь с использованием cookie-файлов в соответствии с ",
      text: "Политикой конфиденциальности.",
      url: "https://x5paket.ru/docs/privacy_policy.pdf",
    }
  );

  const [socialWebs, setSocialWebs] = useState([
    {
      src: vkLogo,
      url: "https://vk.com/x5paket",
    },
  ]);

  return (
    <div className="footer-container">
      <div className="footer-content">
      <div className="info-block">
        <div className="footer-logo">
          <FooterLogo logo={x5Logo}></FooterLogo>
        </div>
        <div className="footer-copyright">
          <Copyright info={info}></Copyright>
        </div>
        <div className="social-web-container">
      <SocialWebButton socialWebLogos={socialWebs}></SocialWebButton>
      </div>
      </div>
      
      <div className="policy-container">
        <Policy policies={policy}></Policy>
      </div>
      </div>
    </div>
  );
};

export default Footer;
