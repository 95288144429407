import "./promocodes.css";
import Header from "@components/header/header";
import Footer from "@components/footer/footer";
import { Outlet, useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import jwt from "jwt-decode";
import { isPromoEarly, isPromoOverExtended } from "helpers/promoHelper";

interface Props {
  token: any
};

const Promocodes = ({ token }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const headerRef: any = useRef()


  useEffect(() => {
    let apiUrl: string;
    if (process.env.REACT_APP_ENV === "dev") {
      apiUrl = process.env.REACT_APP_API_DEV_SERVER_URL as string;
    } else {
      apiUrl = process.env.REACT_APP_API_PROD_SERVER_URL as string;
    }

    const accessToken = searchParams.get("access_token")
    const refreshToken = searchParams.get("refresh_token")

    if (accessToken && refreshToken) {
      localStorage.setItem('access', accessToken)
      localStorage.setItem('refresh', refreshToken)
    } else {
      return;
    }

    if (isPromoOverExtended() && window.location.href != "/promocodes/expired") {
      window.location.href = "/promocodes/expired";
      return;
    }

    if (accessToken) {
      let decodedToken = JSON.stringify(jwt(accessToken as string));
      let parsedToken = JSON.parse(JSON.stringify(jwt(accessToken as string)));
      localStorage.setItem("token", decodedToken as string);
      fetch(`${apiUrl}api/promo-codes`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((responce) => {
          if (responce.ok) {
            return responce.json();
          }
          return responce.text().then((text) => {
            throw JSON.parse(text);
          });
        })
        .then((data) => {
          console.log(data)
          if (isPromoEarly() && process.env.REACT_APP_ENV !== "dev") {
            window.location.href = "/early";
            return;
          }

          if (data.code) {
            localStorage.setItem("is_medovik", data?.is_medovik);
            localStorage.setItem("code", data.code);
            window.location.href = data?.is_medovik ? "/promocodes/list" : "/promocodes/exists";
            return;
          } else {
            window.location.href = "/terms";
            return;
          }
        })
        .catch((error) => {
          console.log(error)
          console.log(error.message)
          if (error.message === "There are no available promo-codes.") {
            window.location.href = "/terms";
          }
          if (error.message === "Promotion period is over") {
            window.location.href = "/promocodes/expired";
          }
          if (error.message === "There is no subscription.") {
            window.location.href = "/promocodes/await";
          }
          if (error.message === "There is no verification.") {
            window.location.href = "/promocodes/await"
          }
          if (error.message === "There is no subscription but user is verified.") {
            window.location.href = "/terms";
          }
          if (error.message === "There are no available promo-codes.") {
            window.location.href = "/promocodes/await";
          }
          if (error.message === "There are no available cacheback.") {
            window.location.href = "/promocodes/await";
          }
          return;
        });
    }

  }, []);

  return (
    <div className="promocodes-container">
      <Header ref={headerRef} token={token}></Header>
      {headerRef.current && (
        <div style={{ width: '100%', minHeight: `${parseInt(headerRef.current.offsetHeight)+50}px`}}></div>
      )}
      <Outlet />
      <Footer></Footer>
    </div>
  );
};

export default Promocodes;
