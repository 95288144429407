import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import close from "@assets/icons/close.svg";
import questionSign from "@assets/icons/questionSign.svg";
interface ModalProps {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  onExit?: ()=> void;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onExit }: ModalProps) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const handleExitModal = () => {
    window.ym(79010596,'reachGoal','x5paket-podarok-clk', {shocolad: 'logout-yes'})
    if (onExit) {
      onExit();
    }
    setModalOpen(false);
  };

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isModalOpen) {
        modalElement.show();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  return (
    <dialog
      ref={modalRef}
      className="exit-modal-popup"
      onKeyDown={handleKeyDown}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="popup-window-container" onClick={handleCloseModal}>
        <div className="close-sign-icon">
          <img src={close} alt=""></img>
        </div>
        <img src={questionSign} alt="" className="question-sign-icon"></img>
        <div className="popup-text-container">
          {" "}
          Вы уверены, что хотите выйти?
        </div>
        <div className="exit-button-container">
          <button className="reject-button" onClick={handleCloseModal}>
            Отменить
          </button>
          <button className="accept-button" onClick={handleExitModal}>
            Да, выйти
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default Modal;
