import { ReactNode } from "react";
import "./stepsText.css";

interface Props {
  data?: {
    header: string | ReactNode;
    text: string | ReactNode;
  };
}

const StepsText = ({ data }: Props) => {
  return (
    <div className="gift-step-text-container">
      <div className="gift-step-header">{data?.header}</div>
      <div className="gift-step-text">{data?.text}</div>
    </div>
  );
};

export default StepsText;
