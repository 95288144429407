import "./landingFooter.css";
import FooterLogo from "@components/footer/footerlogo/footerlogo";
import footerCollabLogo from "@assets/icons/footer-x5logo.svg";

const LandingFooter = () => {

  return (
    <div className="landing-footer-container">
      <div className="landing-footer-content">
        <div className="footer-logo">
          <FooterLogo logo={footerCollabLogo}></FooterLogo>
        </div>
        <div className="landing-policy-container">
        <div className="landing-footer-policy">
          © 2024 ПАО «Корпоративный центр ИКС 5». Сайт использует cookie-файлы. 
          Продолжая пользоваться сайтом, вы соглашаетесь с использованием 
          cookie-файлов в соответствии с <a href='https://x5paket.ru/docs/privacy_policy.pdf' target="_blank" rel="noreferrer">Политикой конфиденциальности.</a>
        </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
