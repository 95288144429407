import GiftHeader from "@components/landingContent/landingGift/giftHeader/giftHeader";
import GiftContent from "@components/landingContent/landingGift/giftContent/giftContent";
import GiftPeriod from "@components/landingContent/landingGift/giftPeriod/giftPeriod";
import litresGift from "@assets/icons/landin-gift-image.png";

import "./promoToEarlyContent.css"

const PromoToEarlyContent = () => {
  const content = <>Вы сможете получить промокод на подписку Литрес, как только начнется акция, осталось совсем чуть-чуть!</>
  const giftHeaderContent = <>Похоже, акция<br /> еще не началась</>;

  return (
    <div className="too-early-content-container">
      <div className="landing-gift-text-container too-early-text-container">
        <GiftPeriod data={"Старт уже скоро!"}></GiftPeriod>
        <GiftHeader data={giftHeaderContent} classList="too-early-gift-header-container"></GiftHeader>
        <GiftContent data={content} classList="too-early-gift-content-container"></GiftContent>
      </div>
      <div className="gift-item-icon-container">
        <img src={litresGift} alt=""></img>
      </div>
    </div>
  );
};

export default PromoToEarlyContent;
