import "./expired.css";
import ExpiredIcon from "./expiredIcon/expiredIcon";
import PromocodeText from "./promocodeText/promocodeText";

const Expired = () => {
  let text = (
    <div>
      Следите за нами в
      официальной группе{" "}
      <a href="https://vk.com/x5paket" target="_blank">
        Вконтакте
      </a>
      , а также на нашем{" "}
      <a href="https://x5paket.ru/" target="_blank">
        сайте
      </a>
      , чтобы быть в курсе следующих уникальных предложений.
    </div>
  );

  return (
    <div className="expired-container">
      <div className="icon-container">
        <ExpiredIcon></ExpiredIcon>
      </div>
      <div className="promocode-container">
        <PromocodeText
          textAlign="center"
          promocodeTitle="Похоже, срок акции закончился"
          promocodeTextWithLinks={text}
        ></PromocodeText>
      </div>
    </div>
  );
};

export default Expired;
