import Header from "@components/header/header";
import "./termsOfAction.css";
import Footer from "@components/footer/footer";
import Terms from "@components/terms/terms";
import { useRef } from "react";

interface Props {
  logo?: string;
  token: any;
}

const TermsOfAction = ({ logo, token }: Props) => {
  const headerRef: any = useRef();

  return (
    <div>
      <Header ref={headerRef} token={token}></Header>
      {headerRef.current && (
        <div style={{ width: '100%', minHeight: `${parseInt(headerRef.current.offsetHeight)}px`}}></div>
      )}
      <div className="terms-container">
        <Terms></Terms>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default TermsOfAction;
