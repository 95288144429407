import { getFormattedEndDateExtended } from "helpers/promoHelper";
import Coupon from "./couponIcon/coupon";
import "./promocodeExists.css";
import PromocodeText from "@components/promoExpired/promocodeText/promocodeText";
const PromocodeExists = () => {
  let token = localStorage.getItem('access')
  if(!token){
    window.location.href='/'
  }

  let text = [
    'Вы не можете получить промокод на подписку Литрес, так как у вас уже подключён сервис «Пакет». Но мы подготовили для вас другой бонус!',
    'Введите этот промокод на сайте или в приложении Литрес и получите скидку 25% на покупки в Литрес.',
    'Промокодом можно воспользоваться только один раз. Промокод действует до '+getFormattedEndDateExtended()+'.'
  ];
  const code = localStorage.getItem('code');

  // if (!code) {
  //   window.location.href='/promocodes/await'
  // }

  return (
    <div className="promocode-exists-container">
      <Coupon
        couponCode={code as string}
        couponText="Ваш промокод на скидку 25%"
      ></Coupon>
      <div className="promocode-exists-text-container">
        <PromocodeText
          textAlign="left"
          promocodeTitle="У вас уже есть сервис «Пакет»"
          promocodeText={text}
          isPlainText={true}
        ></PromocodeText>
      </div>
    </div>
  );
};

export default PromocodeExists;
