import { ReactNode } from "react";
import "./guideHeader.css";

interface Props {
  data: ReactNode;
}

const GuideHeader = ({ data }: Props) => {
  return (
    <div className="guide-header-container">
      <div className="guide-header-text">{data}</div>
    </div>
  );
};

export default GuideHeader;
