import "./stepsCheckpoints.css";

interface Props {
  value?: string;
  isChecked?: boolean;
}

const StepsCheckpoint = ({ value, isChecked }: Props) => {
  return (
    <div
      className="checkpoint-container"
      style={{
        backgroundColor: isChecked ? "#0088FE" : "#DBF3FF",
        color: isChecked ? "#fff" : "#0071D2",
      }}
    >
      <div className="checkpoint-item">{value}</div>
    </div>
  );
};

export default StepsCheckpoint;
