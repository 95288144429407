import GiftHeader from "@components/landingContent/landingGift/giftHeader/giftHeader";
import "./landingGift.css";
import { useState } from "react";
import GiftContent from "./giftContent/giftContent";
import GiftButton from "./giftButton/giftButton";
import GiftPeriod from "./giftPeriod/giftPeriod";
import litresGift from "@assets/icons/landin-gift-image.png";
import GiftTerms from "./giftTerms/giftTerms";
import { getFormattedEndDate, getFormattedStartDate } from "helpers/promoHelper";

const GIFT_TERMS_FILE_URL = process.env.REACT_APP_PROMO_RULES_URL;

const LandingGift = () => {
  const [headerData, setHeaderData] = useState(<>
    Подписка Литрес <br />
    на 3 месяца в подарок!
  </>);
  const [contentData, setContentData] = useState(
    <div>
      Подключите сервис «Пакет» на 1 месяц за 1 рубль 
      и получите Подписку Литрес на 3 месяца бесплатно!
    </div>
  );
  const [periodData, setPeriodData] = useState(`${getFormattedStartDate()} — ${getFormattedEndDate()}`);
  const [termsData, setTermsData] = useState(
    <div>
      Нажимая на кнопку, вы соглашаетесь с <a href={GIFT_TERMS_FILE_URL} target="_blank" rel="noreferrer">условиями акции</a>
    </div>
  );

  return (
    <div className="landing-gift-content-container">
      <div className="landing-gift-text-container">
        <GiftPeriod data={periodData}></GiftPeriod>
        <GiftHeader data={headerData}></GiftHeader>
        <GiftContent data={contentData}></GiftContent>
        <GiftButton data={"Участвовать"}></GiftButton>
        <GiftTerms data={termsData}></GiftTerms>
      </div>
      <div className="gift-item-icon-container">
        <img src={litresGift} alt=""></img>
      </div>
    </div>
  );
};

export default LandingGift;
