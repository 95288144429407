import { ReactNode } from "react";
import "./giftContent.css";

interface Props {
  data?: ReactNode;
  classList?: string;
}

const GiftContent = ({ data, classList }: Props) => {
  return (
    <div className={`gift-content-container ${classList}`}>
      {data}
    </div>
  );
};

export default GiftContent