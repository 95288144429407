import { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import "./promocodeText.css";
interface Props {
  textAlign?: string;
  promocodeTitle?: string;
  promocodeText?: string[];
  promocodeTextWithLinks?: ReactElement;
  isPlainText?: boolean;
  isLink?: boolean;
  listFirstRow?: string;
  listLastRow?: string;
}

const PromocodeText = ({
  textAlign,
  promocodeTitle,
  listFirstRow,
  listLastRow,
  promocodeText,
  isPlainText,
  promocodeTextWithLinks,
  isLink,
}: Props) => {
  const isMobile = function () {
    return window.innerWidth <= 767;
  };
  return (
    <div className="promocode-text-container">
      <div className="promocode-title">
        {promocodeTitle}
      </div>
      <div
        className="promocode-text-content"
        style={{ textAlign: textAlign as CanvasTextAlign }}
      >
        <div className="promocode-text-links">{promocodeTextWithLinks}</div>
        {isPlainText ? (
          <div>
            {promocodeText?.map((item, index) => (
              <div className="promocode-text" key={index}>
                {item}
              </div>
            ))}
          </div>
        ) : (
          <div className="list-container">
            <div className="list-first-row">{listFirstRow}</div>
            <ol className="list-row">
              {promocodeText?.map((item, index) => (
                <li key={index} className="list-item">
                  {item}
                </li>
              ))}
            </ol>
            <div className="list-last-row">{listLastRow}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromocodeText;
