import "./landingHeader.css";
import Logo from "@components/header/logo/logo";
import CollabLogo from "./collabLogo/collabLogo";
import collabLogo from "@assets/icons/litres-header-logo.svg";
import logo from '@assets/icons/header-logo.svg';

const LandingHeader = () => {
  return (
    <div className="landing-header-container">
      <div className="landing-header-content">
        <div className="landing-logo-container">
           <div className="logo-icon-landing"> 
          <Logo logo={logo}></Logo>
          </div>
          <CollabLogo logo={collabLogo}></CollabLogo>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;