import React from "react";
import "./giftHeader.css";

interface Props {
  data?: string | React.ReactNode;
  classList?: string;
}

const GiftHeader = ({ data, classList }: Props) => {
  return (
    <div className={`gift-header-container ${classList}`}>
      {data}
    </div>
  );
};

export default GiftHeader;