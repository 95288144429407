import LandingHeader from "@components/landingHeader/landingHeader";
import LandingFooter from "@components/landingFooter/landingFooter";
import PromoToEarlyContent from "@components/promoToEarlyContent/promoToEarlyContent";

import "./promoToEarly.css"

interface Props {
  logo?: string;
}


const PromoToEarly = () => {

  return (
    <div>
      <LandingHeader></LandingHeader>
      <div className="landing-height-container">
        <div className="landing-gift to-early-landing-gift">
          <PromoToEarlyContent></PromoToEarlyContent>
        </div>
      </div>
      <LandingFooter></LandingFooter>
    </div>
  );
};

export default PromoToEarly;
