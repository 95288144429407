import NotifyModal from "@components/notifyModal/noitifyModal";
import "./promocodeList.css";
import { useState } from "react";
import litresIcon from "@assets/icons/promocode-image.png";
import copyIcon from "@assets/icons/copyIcon.svg";
import { getFormattedEndDateExtended, getFormattedStartDate, isVerifiedAndSubscription } from "helpers/promoHelper";

interface Props {
  data?: string;
  token?: any;
}

const PromocodeList = ({ data, token }: Props) => {
  if (!token) {
    window.location.href = "/";
  }
  let code = localStorage.getItem("code");
  let is_medovik = localStorage.getItem("is_medovik") === 'true';
  // if (!code || (token && !isVerifiedAndSubscription(token))) {
  //   window.location.href = "/promocodes/await";
  // }

  if (!is_medovik) {
    window.location.href = "/promocodes/exists"
  }

  const [showModal, setModal] = useState(false);
  const handleClick = () => {
    navigator.clipboard.writeText(code as string);
    setModal(true);
    setTimeout(() => {
      setModal(false);
    }, 1000);
  };

  return (
    <div className="promocode-coupon-container">
      <div className="promo-coupon">
        <div className="promocode-coupon-icon">
          {" "}
          <img src={litresIcon} alt=""></img>
        </div>
        <div className="promocode-coupon-text-header">
          Подписка Литрес на 3 месяца
        </div>
        <div className="promocode-coupon-text-period">
          Срок действия промокода
        </div>
        <div className="promocode-coupon-text-dates">
          {getFormattedStartDate()} — {getFormattedEndDateExtended()}
        </div>
        <div className="promocode-coupon-code" onClick={handleClick}>
          {code} <img src={copyIcon} alt=""></img>
        </div>
      </div>
      {showModal ? (
        <NotifyModal message="Ваш промокод успешно скопирован"></NotifyModal>
      ) : null}
    </div>
  );
};

export default PromocodeList;
