import { CSSProperties, useEffect } from "react";
import "./stepsProgressbar.css";

interface Props {
  percents?: string;
}
const StepsProgressbar = ({ percents }: Props) => {
  let containerStyles;
  let fillerStyles;
  window.addEventListener("resize", (event) => {
    if (window.innerWidth >= 767) {
      fillerStyles = {
        height: "100%",
        width: `${percents}%`,
        backgroundColor: "#0088fe",
        borderRadius: "inherit",
        textAlign: "right",
      };

      containerStyles = {
        height: 3,
        width: "100%",
        backgroundColor: "#dbf3ff",
      };
    } else {
      fillerStyles = {
        height: `${percents}%`,
        width: "100%",
        backgroundColor: "#0088fe",
        borderRadius: "inherit",
        textAlign: "right",
      };

      containerStyles = {
        height: "100%",
        width: 3,
        backgroundColor: "#dbf3ff",
      };
    }
  });
  if (window.innerWidth >= 767) {
    fillerStyles = {
      height: "100%",
      width: `${percents}%`,
      backgroundColor: "#0088fe",
      borderRadius: "inherit",
      textAlign: "right",
    };

    containerStyles = {
      height: 3,
      width: "100%",
      backgroundColor: "#dbf3ff",
    };
  } else {
    fillerStyles = {
      height: `${percents}%`,
      width: "100%",
      backgroundColor: "#0088fe",
      borderRadius: "inherit",
      textAlign: "right",
    };

    containerStyles = {
      height: "100%",
      width: 3,
      backgroundColor: "#dbf3ff",
    };
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles as CSSProperties}></div>
    </div>
  );
};

export default StepsProgressbar;
