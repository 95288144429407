import { Outlet } from "react-router-dom";
import "./expiredIcon.css";
import expiredIcon from "@assets/icons/expiredIcon.svg";

const ExpiredIcon = () => {
  return (
    <div className="expired-icon-container">
      <img src={expiredIcon} alt="" />
    </div>
  );
};

export default ExpiredIcon;
