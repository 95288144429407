import "./policy.css";

interface Props {
  policies: {
    description: string;
    text: string;
    url: string;
  };
}

const Policy = ({ policies }: Props) => {
  return (
    <div className="policies-container">
      {policies.description}
      <a href={policies.url} target="_blank" rel="noreferrer">
        {policies.text}
      </a>
    </div>
  );
};

export default Policy;
