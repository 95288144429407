import { useNavigate } from "react-router-dom";
import "./navigation.css";

const Navigation = ({ navigationText, active, navigationPath, disabled }) => {
  const navigate = useNavigate();

  function handleClick(){
    if (!disabled) {
      navigate(navigationPath)
    }
  }

  return (
    <div
      className="navigation-text"
      style={{ color: active ? "#0088FE" : "#14171A", cursor: !disabled ? 'pointer' : 'auto' }}
      onClick={handleClick}
    >
      {navigationText}
    </div>
  );
};

export default Navigation;
