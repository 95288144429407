import "./header.css";
import Logo from "@components/header/logo/logo";
import ButtonConnect from "@components/header/buttonConnect/buttonConnect";
import Profile from "@components/header/profile/profile";
import Navigation from "@components/header/navigation/navigation";
import { useLocation } from "react-router-dom";
import mobileLogo from "@assets/icons/mobileLogo.svg";
import mobileNav from "@assets/icons/mobileNavigation.svg";
import { useEffect, useState, forwardRef } from "react";
import exit from "@assets/icons/mobileExitIcion.svg";
import profile from "@assets/icons/mobileProfile.svg";
import close from "@assets/icons/close.svg";
import Modal from "@components/modal/modal";
import { isPromoNow, isPromoOver, isPromoOverExtended, isSubscription, isSubscriptionBlocked, isVerified, isVerifiedAndNoSubscription } from "helpers/promoHelper";

interface Props {
  token: any
};

const Header = forwardRef(({ token }: Props, ref: any) => {
  const [showPopup, setPopup] = useState(false);
  const [showModal, setModal] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const location = useLocation();
  const handleCloseModal = () => {
    setModal(false);
  };

  useEffect(() => {
    const flag = (token?.subscription || false).toString() === "false";
    setIsDisable(flag);
  }, [token]);

  const handleExitModal = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("code");
    window.location.href = "/";
  };

  // const isSubsriptionDisabled = () => {
  //   if (token) {
  //     console.log('token.subscription: ', token.subscription)
  //     setIsDisable(!token.subscription)
  //   } else {
  //     setIsDisable(true);
  //   }
  // }

  const isMyPromocodesActive = () => {
    const href = window.location.href;
    return href.includes('/list') || href.includes('/exists');
  }

  const includesExist = () => {
    const href = window.location.href;
    return href.includes('/exists');
  }
  
  isMyPromocodesActive()
  return window.innerWidth > 767 ? (
    <div className="header-container">
      <div className="header-content">
        <div className="logo-container">
          <Logo></Logo>
        </div>
        <div className="navigation-container">
          <div className="navigation-item">
            <Navigation
              navigationPath={"/terms"}
              navigationText={"Условия акции"}
              active={!location.pathname.includes("promocodes")}
              disabled={!isDisable || isPromoOver()}
            ></Navigation>
          </div>
          <div className="navigation-item">
            <Navigation
              navigationPath={includesExist() ? "/promocodes/exists" : "/promocodes/list" }
              navigationText={"Мои промокоды"}
              active={isMyPromocodesActive()}
              disabled={isDisable || isPromoOverExtended()}
            ></Navigation>
          </div>
        </div>
        <div className="profile-container">
          <div className="profile-item">
            <ButtonConnect
              buttonText={"Подключить сервис"}
              disabled={!isDisable || !isPromoNow()}
              color={"#DBF3FF"}
              width={"16.125rem"}
              isHeader={true}
            ></ButtonConnect>
          </div>
          <div className="profile-item">
            <Profile name={token?.name}></Profile>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="mobile-header-container" ref={ref}>
      <div className="mobile-navigation-container">
        <img src={mobileNav} alt="" onClick={() => setPopup(!showPopup)}></img>
        {showPopup ? (
          <div className="mobile-popup">
            <div className="mobile-popup-header-container">
              <div className="mobile-profile">
                <img src={profile} className="mobile-profile-icon" alt=""></img>
                <div className="mobile-profile-name">{token?.name}</div>
              </div>
              <div className="mobile-close-icon-container">
                <img
                  src={close}
                  alt=""
                  onClick={() => setPopup(!showPopup)}
                ></img>
              </div>
            </div>
            <div className="mobile-navigation">
              <div className="mobile-navigation-item">
                <Navigation
                  navigationPath={"/terms"}
                  navigationText={"Условия акции"}
                  active={!location.pathname.includes("promocodes")}
                  disabled={!isDisable || isPromoOver()}
                ></Navigation>
              </div>
              <div className="mobile-navigation-item">
                <Navigation
                  navigationPath={includesExist() ? "/promocodes/exists" : "/promocodes/list" }
                  navigationText={"Мои промокоды"}
                  active={isMyPromocodesActive()}
                  disabled={isDisable || isPromoOverExtended()}
                ></Navigation>
              </div>
            </div>
            <div className="mobile-exit">
              <img src={exit} alt=""></img>
              <div
                className="mobile-exit-text"
                onClick={(e) => {
                  window.ym(79010596,'reachGoal','x5paket-podarok-clk', {shocolad: 'logout'})
                  setModal(true);
                  setPopup(false);
                  e.stopPropagation();
                }}
              >
                Выйти
              </div>
            </div>
          </div>
        ) : null}
        {showModal ? (
          <Modal
            isOpen={showPopup}
            onClose={handleCloseModal}
            onExit={handleExitModal}
          ></Modal>
        ) : null}
      </div>
      <div className="mobile-logo-container">
        <img src={mobileLogo} alt=""></img>
      </div>
      <div className="mobile-button-container">
        <ButtonConnect
          buttonText={"Подключить сервис"}
          disabled={!isDisable || !isPromoNow()}
          color={"#DBF3FF"}
          isHeader={true}
        ></ButtonConnect>
      </div>
    </div>
  );
});

export default Header;
