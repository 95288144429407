import { useLayoutEffect, useEffect, useRef, useState } from "react";
import "./landingSteps.css";
import StepsCheckpoint from "./stepsCheckpoints/stepsCheckpoints";
import StepsProgressbar from "./stepsProgressbar/stepsProgressbar";
import StepsText from "./stepsText/stepsText";

const LandingSteps = () => {
  const [percents, setPercents] = useState(0);
  
  const [secondPercents, setSecondPercents] = useState(0);
  const [checkbox, setCheckbox] = useState([true, false, false]);
  const [stepsText, setStepsText] = useState([
    {
      header: "Зарегистрируйтесь",
      text: (
        <span>
          Нажмите кнопку «Участвовать» <div>и зарегистрируйтесь в X5ID</div>
        </span>
      )
    },
    {
      header: (
        <span>
          Подключите <div>сервис «Пакет» за 1 рубль</div>
        </span>
      ),
      text: "Получайте кешбэк до 50% за покупки в «Пятёрочке» и «Перекрёстке»!",
    },
    {
      header: (
        <span>
          Получите уникальный <div>промокод</div>
        </span>
      ),
      text: "Введите его на сайте или в приложении Литрес и получите Подписку Литрес на 3 месяца  в подарок!",
    },
  ]);

  const anchorRef = useRef();
  const loadedCheckboxIdx = useRef(0);
  const anchorContextRef = useRef(0);
  const freezedY = useRef(0);

  const isMobile = function () {
    return window.innerWidth <= 767;
  };

  const isProgressVisible = () => {
    const anchBox = anchorRef.current.getBoundingClientRect();
    return anchBox.top + anchBox.height * (isMobile() ? 1 : 2) < window.innerHeight;
  }


  const onScroll = e => {
    if (isProgressVisible() && loadedCheckboxIdx.current == 0 || loadedCheckboxIdx.current == 1) {
      blockScroll()
    }
  }

  const startFillAnimation = (speed = 1) => {
    const isFirstBar = loadedCheckboxIdx.current === 0;

    let curPercent = 0;
    const interval = setInterval(() => {
      curPercent += speed

      // first bar
      if (isFirstBar) {
        setPercents(curPercent);
      } 
      // second bar
      else {
        setSecondPercents(curPercent);
      }

      // animation ended
      if (curPercent >= 100) {
        // remove animation
        clearInterval(interval);
        // update checkbox as state and as ref
        setCheckbox([true, true, loadedCheckboxIdx.current === 1]);
        loadedCheckboxIdx.current++;
        // enable scroll
        unblockScroll()
        // scroll to bottom section if its second bar
        if (!isFirstBar) {
          const anchBox = anchorRef.current.getBoundingClientRect();
          // get bottom margin for landing steps section
          const anchBoxBottomOffset = parseFloat(
            window.getComputedStyle(anchorRef.current).getPropertyValue('margin-bottom'));
          // scroll to next section
          // !isMobile() && anchorContextRef.current.scrollTo({
          //   behavior: "smooth",
          //   top: freezedY.current + anchBox.top + anchBox.height + anchBoxBottomOffset
          // })
        }
      }
    }, 1000/60)
  }
  
  const unblockScroll = () => {
    if (loadedCheckboxIdx.current == 1) {
      anchorContextRef.current.addEventListener("scroll", onScroll);
    } else {
      // unblock scroll blocker only all progress bars are filled
      anchorContextRef.current.removeEventListener("scroll", scrollBlocker)
    }
  }

  const scrollBlocker = () => {
    if (!isMobile()) {
      // anchorContextRef.current.scrollTo(0, freezedY.current);
    }
  }

  const blockScroll = () => {
    freezedY.current = anchorContextRef.current.scrollTop;
    anchorContextRef.current.removeEventListener("scroll", onScroll)
    anchorContextRef.current.addEventListener("scroll", scrollBlocker);
    
    startFillAnimation(2);
  }

  useEffect(() => {
    anchorContextRef.current = document.getElementsByClassName("scroll-listener")[0];
    anchorContextRef.current.addEventListener("scroll", onScroll);
    document.addEventListener("touchmove", e => e.preventDefault())
  }, [])

  return (
    <div className="landing-steps-container" ref={anchorRef}>
      <div className="landing-steps-progress-container">
        <StepsCheckpoint value="1" isChecked={checkbox[0]}></StepsCheckpoint>
        <StepsProgressbar percents={percents.toString()}></StepsProgressbar>
        <StepsCheckpoint value="2" isChecked={checkbox[1]}></StepsCheckpoint>
        <StepsProgressbar
          percents={secondPercents.toString()}
        ></StepsProgressbar>
        <StepsCheckpoint value="3" isChecked={checkbox[2]}></StepsCheckpoint>
      </div>
      <div className="landing-steps-text-container">
        {stepsText.map((item, index) => {
          return <StepsText data={item}></StepsText>;
        })}
      </div>
    </div>
  );
};

export default LandingSteps;
